
import Vue from 'vue';

export default {
  name: 'PartnerPortal',
  mounted() {
    Vue.prototype?.$mixpanel?.track('partnerPortal', {
      referrer: document.referrer
    });
  }
};
